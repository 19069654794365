import { chakra, Icon, Th, Thead, Tr } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import type { ComponentProps, FunctionComponent } from 'react'

import ChevronIcon from 'assets/chevron.svg?component'
import type { UseTable } from 'hooks/useTable'
import { SortOrder } from 'hooks/useTable/reducer'

export type HeaderRow<COLUMN> = readonly [
  columnName: COLUMN,
  translationKey: TranslationKeys['common'],
  props: ComponentProps<typeof Th>,
]

export type Headers<COLUMN = string> = ReadonlyArray<HeaderRow<COLUMN>>

type UseTableSortKeys = 'changeSort' | 'sortColumn' | 'sortOrder'
type SortProps = Pick<UseTable, UseTableSortKeys>

interface TableHeaderWithSortProps extends SortProps {
  headers: Headers
}

interface TableHeaderWithoutSortProps extends Partial<Record<UseTableSortKeys, never>> {
  headers: Headers
}

/**
 * Discriminated union type to determine if the table has sorting or not
 * and if it has sorting, it will require all the sort props
 */
type TableHeaderProps = TableHeaderWithSortProps | TableHeaderWithoutSortProps

const TAG = 'TableHeader'

const TableHeader: FunctionComponent<TableHeaderProps> = ({ changeSort, sortColumn, sortOrder, headers }) => {
  const { t } = useTranslation()

  return (
    <Thead>
      <Tr>
        {headers.map(([column, translationKey, props]) => (
          <Th key={column} data-column={column} onClick={changeSort} cursor="pointer" userSelect="none" {...props}>
            {t(translationKey)}

            <chakra.span ml={2}>
              {sortColumn === column && sortOrder !== SortOrder.NONE && (
                <Icon
                  as={ChevronIcon}
                  boxSize={3}
                  transform={sortOrder === SortOrder.ASC ? 'rotate(90deg)' : 'rotate(-90deg)'}
                />
              )}
            </chakra.span>
          </Th>
        ))}
      </Tr>
    </Thead>
  )
}

TableHeader.displayName = TAG

export default TableHeader
