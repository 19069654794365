import { Box, Progress, TableContainer as ChakraTableContainer, SystemStyleInterpolation } from '@chakra-ui/react'
import React, { ComponentProps, FunctionComponent } from 'react'

type TableContainerProps = ComponentProps<typeof ChakraTableContainer> & {
  /**
   * @default false
   */
  isFetching?: boolean
  /**
   * Pass `0` to disable sticky columns
   * @default 2
   */
  stickyColumns?: number
  children: React.ReactNode
}

const TAG = 'TableContainer'

const DEFAULT_STICKY_COLUMNS = 2
const FIRST_COLUMNS_SIZE = '8rem'

const getStyles = (options: { stickyColumns: number }) =>
  ({
    container: {
      sx: {
        /* All columns till STICKY_COLUMNS */
        [[`& td:nth-of-type(-n + ${options.stickyColumns})`, `& th:nth-of-type(-n + ${options.stickyColumns})`].join()]:
          {
            position: { md: 'sticky' },
            insetInlineStart: FIRST_COLUMNS_SIZE,
            backgroundColor: 'white',
            // Chakra UI doesn't resolve colors in the box-shadow
            boxShadow: { md: 'inset -1px 0 0 var(--nm-colors-gray-200)' },
          },
        /* First column */
        [['& td:first-of-type', '& th:first-of-type'].join()]: {
          insetInlineStart: 0,
          wordBreak: 'break-word',
          whiteSpace: 'normal',
          maxWidth: FIRST_COLUMNS_SIZE,
          minWidth: FIRST_COLUMNS_SIZE,
          boxShadow: 'none',
        },
      },
    },
  } as const satisfies SystemStyleInterpolation)

/**
 * Customer TableContainer component that adds a progress bar on top of the table when isFetching is true.
 */
const TableContainer: FunctionComponent<TableContainerProps> = ({
  isFetching = false,
  stickyColumns = DEFAULT_STICKY_COLUMNS,
  children,
  ...props
}) => {
  const styles = getStyles({ stickyColumns })
  return (
    <ChakraTableContainer position="relative" {...styles.container} {...props}>
      {isFetching ? <Progress size="xs" colorScheme="red" isIndeterminate /> : <Box height="1" />}
      {children}
    </ChakraTableContainer>
  )
}

TableContainer.displayName = TAG

export default TableContainer
