import { useMemo } from 'react'

function useTablePage<DATA>(items: ReadonlyArray<DATA>, pageSize: number, pageIndex: number): DATA[] {
  return useMemo<DATA[]>(() => {
    if (!items?.length) {
      return []
    }

    const totalCount = items.length
    const totalPages = Math.ceil(totalCount / pageSize)
    const page = pageIndex > totalPages ? totalPages : pageIndex

    const start = (page - 1) * pageSize
    const end = start + pageSize > totalCount ? totalCount : start + pageSize

    return items.slice(start, end)
  }, [items, pageIndex, pageSize])
}

export default useTablePage
