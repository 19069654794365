import type { TableFilter } from 'components/modules/Equipment/Filters/types'

export const ACTION_NEXT_PAGE = 'TABLE_NEXT_PAGE'
interface TableActionNextPage {
  type: typeof ACTION_NEXT_PAGE
  payload?: number
}

export const ACTION_PREVIOUS_PAGE = 'TABLE_PREVIOUS_PAGE'
interface TableActionPreviousPage {
  type: typeof ACTION_PREVIOUS_PAGE
  payload?: number
}

export const ACTION_PAGE_SIZE = 'TABLE_PAGE_SIZE'
interface TableActionPageSize {
  type: typeof ACTION_PAGE_SIZE
  payload: number
}

export const ACTION_SORT = 'TABLE_SORT'
interface TableActionSort {
  type: typeof ACTION_SORT
  payload: string
}

export const ACTION_SEARCH = 'TABLE_SEARCH'
interface TableActionSearch {
  type: typeof ACTION_SEARCH
  payload: string
}

export const ACTION_FILTER = 'TABLE_FILTER'
interface TableActionFilter {
  type: typeof ACTION_FILTER
  payload: TableFilter
}

export const ACTION_RESET_FILTERS = 'TABLE_RESET_FILTERS'
interface TableActionResetFilters {
  type: typeof ACTION_RESET_FILTERS
}

export const ACTION_SET_UNIQUE_KEY = 'SET_UNIQUE_KEY'
interface TableActionSetUniqueKey {
  type: typeof ACTION_SET_UNIQUE_KEY
  payload: string
}

export type TableActions =
  | TableActionNextPage
  | TableActionPreviousPage
  | TableActionSetUniqueKey
  | TableActionPageSize
  | TableActionSort
  | TableActionSearch
  | TableActionFilter
  | TableActionResetFilters
