import type { Reducer } from 'react'

import type { TableFilter } from 'components/modules/Equipment/Filters/types'
import {
  ACTION_FILTER,
  ACTION_NEXT_PAGE,
  ACTION_PAGE_SIZE,
  ACTION_PREVIOUS_PAGE,
  ACTION_RESET_FILTERS,
  ACTION_SET_UNIQUE_KEY,
  ACTION_SEARCH,
  ACTION_SORT,
  TableActions,
} from 'hooks/useTable/actions'

export const PAGE_SIZES = [10, 20, 50, 100]
export const PAGE_SIZE_DEFAULT = 10

export enum SortOrder {
  NONE,
  ASC,
  DESC,
}

export interface TableState {
  /**
   * Unique key to reset the state when it changes.
   */
  _uniqueId: string
  pageIndex: number
  pageSize: ArrayValues<typeof PAGE_SIZES>
  sortColumn: string
  sortOrder: SortOrder
  search: string
  filters: TableFilter[]
}

export const initialState: TableState = {
  _uniqueId: '',
  pageIndex: 1,
  pageSize: PAGE_SIZES[0],
  sortColumn: '',
  sortOrder: SortOrder.NONE,
  search: '',
  filters: [],
}

const tableReducer: Reducer<TableState, TableActions> = (state, action) => {
  switch (action.type) {
    case ACTION_NEXT_PAGE:
      return {
        ...state,
        pageIndex: action.payload ?? state.pageIndex + 1,
      }
    case ACTION_PREVIOUS_PAGE:
      const newPage = action.payload ?? state.pageIndex - 1
      return {
        ...state,
        pageIndex: newPage > 0 ? newPage : 1,
      }
    case ACTION_PAGE_SIZE:
      return {
        ...state,
        pageIndex: 1,
        pageSize: action.payload,
      }
    case ACTION_SORT:
      const sortOrder =
        state.sortColumn === action.payload
          ? state.sortOrder === SortOrder.NONE
            ? SortOrder.ASC
            : state.sortOrder === SortOrder.ASC
            ? SortOrder.DESC
            : SortOrder.NONE
          : SortOrder.ASC

      return {
        ...state,
        sortColumn: action.payload,
        sortOrder,
      }
    case ACTION_SEARCH:
      return {
        ...state,
        search: action.payload,
        pageIndex: 1,
      }
    case ACTION_FILTER:
      return {
        ...state,
        filters: [...state.filters.filter((_) => _.key !== action.payload.key), action.payload].filter(
          // Filter out empty filters
          (filter) => filter.selectedOptions.length,
        ),
        pageIndex: 1,
      }

    case ACTION_RESET_FILTERS:
      return {
        ...state,
        filters: [],
        pageIndex: 1,
      }
    case ACTION_SET_UNIQUE_KEY:
      const newKey = action.payload
      if (newKey === state._uniqueId) {
        return state
      }
      return {
        ...state,
        ...initialState,
      }

    default:
      return state
  }
}

export default tableReducer
