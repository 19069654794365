import type { NextRouter } from 'next/router'

import { TableState, PAGE_SIZES, SortOrder } from 'hooks/useTable/reducer'

/**
 * @returns Initial state for the reducer based on the query params.
 * @param uniqueId Based on this uniqueId, the state will be reset when the key changes.
 */
function getInitialState(router: NextRouter, uniqueId: string) {
  return (state: TableState): TableState => {
    const queryFromLocation =
      typeof location !== 'undefined'
        ? Object.fromEntries(Array.from(new URLSearchParams(location.search).entries()))
        : {}
    const allQueryParams = { ...queryFromLocation, ...router.query }
    const { pageIndex, pageSize, sortColumn, sortOrder, search, filters } = allQueryParams

    const _pageIndex = Number(pageIndex) || state.pageIndex
    return {
      ...state,
      _uniqueId: uniqueId,
      filters: filters && typeof filters === 'string' ? JSON.parse(filters) : state.filters,
      pageIndex: _pageIndex > 0 ? _pageIndex : state.pageIndex,
      pageSize: PAGE_SIZES.includes(Number(pageSize)) ? Number(pageSize) : state.pageSize,
      sortColumn: sortColumn && typeof sortColumn === 'string' ? JSON.parse(sortColumn) : state.sortColumn,
      sortOrder: [SortOrder.ASC, SortOrder.DESC].includes(Number(sortOrder))
        ? (Number(sortOrder) as SortOrder)
        : state.sortOrder,
      search: search && typeof search === 'string' ? JSON.parse(search) : state.search,
    }
  }
}

export default getInitialState
