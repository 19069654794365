import { SystemStyleInterpolation, Tbody, Td, Tr } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

interface TableErrorTBodyProps {
  columns: number
  rows?: number
}

const TAG = 'TableErrorTBody'

const styles = { td: { bg: 'white !important' } } as const satisfies SystemStyleInterpolation

/* Table body with empty rows to be displayed as a background for the message in case of table error or no results */
const TableErrorTBody: FunctionComponent<TableErrorTBodyProps> = ({ columns, rows = 4 }) => (
  <Tbody>
    {Array.from(Array(rows).keys()).map((index) => (
      <Tr key={index} h={12}>
        <Td colSpan={columns} {...styles.td} />
      </Tr>
    ))}
  </Tbody>
)

TableErrorTBody.displayName = TAG

export default TableErrorTBody
