import type { MouseEvent } from 'react'

/**
 * This function returns the page number that should be used when changing page
 * for the next or previous page. It is based on the `data-next-page` and `data-prev-page` attributes.
 */
function getPage(type: 'next' | 'prev', pageOrEvent?: number | MouseEvent<HTMLElement>): number | undefined {
  if (typeof pageOrEvent === 'number') {
    return pageOrEvent
  }

  const element = pageOrEvent?.target as HTMLElement
  const page = Number(element.getAttribute(`data-${type}-page`))
  return page || void 0
}

export default getPage
