import { Flex, Text, Icon, Select, IconButton } from '@chakra-ui/react'
import isEqual from 'lodash/isEqual'
import useTranslation from 'next-translate/useTranslation'
import { FunctionComponent, memo } from 'react'

import ChevronIcon from 'assets/chevron.svg?component'
import { EMPTY_VALUE } from 'config/const'
import type { UseTable } from 'hooks/useTable'
import { PAGE_SIZES } from 'hooks/useTable/reducer'

interface TablePaginationProps
  extends Pick<UseTable, 'changePageSize' | 'nextPage' | 'previousPage' | 'pageIndex' | 'pageSize'> {
  totalCount: number
}

const styles = {
  wrapper: {
    minH: '3.25rem',
    px: 6,
    direction: { base: 'column', sm: 'row' },
    align: { base: 'start', sm: 'center' },
    justify: 'space-between',
  },
  select: {
    maxW: 20,
    border: 'none',
    _focusVisible: {
      border: 'none',
    },
  },
  button: {
    bg: 'transparent',
    color: 'primary.red',
    _hover: {
      bg: 'gray.100',
    },
    _disabled: {
      bg: 'transparent',
      color: 'gray.300',
    },
  },
  pageSizeSelector: {
    align: 'center',
    w: { base: 'full', md: '50%' },
  },
  pagination: {
    align: 'center',
    w: 'full',
    justify: {
      base: 'start',
      sm: 'end',
    },
  },
} as const

const TAG = 'TablePagination'

const TablePagination: FunctionComponent<TablePaginationProps> = ({
  pageIndex,
  nextPage,
  previousPage,
  pageSize,
  changePageSize,
  totalCount,
}) => {
  const { t } = useTranslation()

  const totalPages = Math.ceil(totalCount / pageSize)
  const page = pageIndex > totalPages ? totalPages : pageIndex
  const start = (page - 1) * pageSize + 1
  const end = page * pageSize > totalCount ? totalCount : page * pageSize
  const text = totalCount > 0 ? `${start} - ${end}` : null

  return (
    <Flex {...styles.wrapper}>
      <Flex as="label" {...styles.pageSizeSelector}>
        <Text as="span" mt="-2px" display="flex">
          {t('components.table.rows-per-page')}
        </Text>
        <Select onChange={changePageSize} value={pageSize} {...styles.select}>
          {PAGE_SIZES.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </Select>
      </Flex>

      <Flex {...styles.pagination} data-current-page={page} data-total-pages={totalPages} data-total-count={totalCount}>
        <Text mr={8}>
          <b>{text || EMPTY_VALUE}&nbsp;</b>
          {t('components.table.of')}
          <b>&nbsp;{totalCount || EMPTY_VALUE}</b>
        </Text>
        <IconButton
          onClick={previousPage}
          isDisabled={pageIndex <= 1}
          data-prev-page={page - 1}
          aria-label={t('components.table.previous-page')}
          icon={
            <Icon as={ChevronIcon} aria-hidden="true" boxSize={3} transform="rotate(180deg)" pointerEvents="none" />
          }
          {...styles.button}
        />
        <IconButton
          onClick={nextPage}
          data-next-page={page + 1}
          isDisabled={pageIndex >= totalPages}
          aria-label={t('components.table.next-page')}
          icon={<Icon as={ChevronIcon} aria-hidden="true" boxSize={3} pointerEvents="none" />}
          {...styles.button}
        />
      </Flex>
    </Flex>
  )
}

TablePagination.displayName = TAG

export default memo(TablePagination, isEqual)
