import { Tbody, Td, Tr } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

import SectionLoading from 'components/elements/SectionLoading'

interface TableLoadingProps {
  columns: number
}

const TAG = 'TableLoading'

const TableLoading: FunctionComponent<TableLoadingProps> = ({ columns }) => (
  <Tbody>
    <Tr>
      <Td colSpan={columns}>
        <SectionLoading />
      </Td>
    </Tr>
  </Tbody>
)

TableLoading.displayName = TAG

export default TableLoading
