import { useRouter } from 'next/router'
import { ChangeEvent, MouseEvent, useCallback, useEffect, useReducer } from 'react'

import type { TableFilter } from 'components/modules/Equipment/Filters/types'
import {
  ACTION_FILTER,
  ACTION_NEXT_PAGE,
  ACTION_PAGE_SIZE,
  ACTION_PREVIOUS_PAGE,
  ACTION_RESET_FILTERS,
  ACTION_SEARCH,
  ACTION_SET_UNIQUE_KEY,
  ACTION_SORT,
} from 'hooks/useTable/actions'
import tableReducer, { initialState, TableState } from 'hooks/useTable/reducer'
import getInitialState from 'hooks/useTable/utils/getInitialState'
import getPage from 'hooks/useTable/utils/getPage'
import useUpdateQueryParams from 'hooks/useUpdateQueryParams'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

export interface UseTable extends TableState {
  scrollToTable: VoidFunction
  nextPage: (pageOrEvent?: number | MouseEvent<HTMLElement>) => void
  previousPage: (pageOrEvent?: number | MouseEvent<HTMLElement>) => void
  changePageSize: (sizeOrEvent: number | ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void
  changeSort: (sortColumnOrEvent: string | MouseEvent<HTMLElement>) => void
  setSearch: (searchOrEvent: string | ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void
  setFilter: (filters: TableFilter) => void
  resetFilters: () => void
}

/**
 * Hook to manage table state.
 *
 * Note:
 * - If you want to scroll to the top of the table when changing page, you need to add
 * the attribute `data-pagination-scroll="true"` to the container element.
 */
function useTable(): UseTable {
  const { erpId } = useBusinessUnit()

  const router = useRouter()
  const [value, dispatch] = useReducer<typeof tableReducer, TableState>(
    tableReducer,
    initialState,
    getInitialState(router, erpId),
  )
  useUpdateQueryParams({ ...value, _uniqueId: null })

  const scrollToTable = useCallback(() => {
    const element = document.querySelector('[data-pagination-scroll="true"]')
    if (element && 'offsetTop' in element && typeof element.offsetTop === 'number') {
      const scrollTop = element.offsetTop
      requestIdleCallback(() => window.scrollTo({ behavior: 'smooth', top: scrollTop }))
    }
  }, [])

  const nextPage = useCallback<UseTable['nextPage']>(
    (pageOrEvent) => {
      scrollToTable()
      const page = getPage('next', pageOrEvent)
      return dispatch({ type: ACTION_NEXT_PAGE, payload: page })
    },
    [scrollToTable],
  )
  const previousPage = useCallback<UseTable['previousPage']>(
    (pageOrEvent) => {
      scrollToTable()
      const page = getPage('prev', pageOrEvent)
      return dispatch({ type: ACTION_PREVIOUS_PAGE, payload: page })
    },
    [scrollToTable],
  )

  const changePageSize = useCallback<UseTable['changePageSize']>((sizeOrEvent) => {
    const pageSize = typeof sizeOrEvent === 'number' ? sizeOrEvent : Number(sizeOrEvent.target.value)
    return dispatch({ type: ACTION_PAGE_SIZE, payload: pageSize })
  }, [])

  const changeSort = useCallback<UseTable['changeSort']>((sortColumnOrEvent) => {
    const sortColumns =
      typeof sortColumnOrEvent === 'string'
        ? sortColumnOrEvent
        : (sortColumnOrEvent.target as HTMLElement).getAttribute('data-column')

    return dispatch({ type: ACTION_SORT, payload: sortColumns || '' })
  }, [])

  const setSearch = useCallback<UseTable['setSearch']>((searchOrEvent) => {
    const search = typeof searchOrEvent === 'string' ? searchOrEvent : searchOrEvent.target.value
    return dispatch({ type: ACTION_SEARCH, payload: search })
  }, [])

  const setFilter = useCallback<UseTable['setFilter']>((filter) => {
    return dispatch({ type: ACTION_FILTER, payload: filter })
  }, [])

  const resetFilters = useCallback<UseTable['resetFilters']>(() => {
    return dispatch({ type: ACTION_RESET_FILTERS })
  }, [])

  // Reset state when business unit changes
  useEffect(() => {
    // Used to reset the state when is free of side effects
    setTimeout(() => {
      dispatch({ type: ACTION_SET_UNIQUE_KEY, payload: erpId })
    })
  }, [erpId])

  return {
    ...value,
    scrollToTable,
    nextPage,
    previousPage,
    changePageSize,
    changeSort,
    setSearch,
    setFilter,
    resetFilters,
  }
}

export default useTable
