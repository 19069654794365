import { SortOrder } from 'hooks/useTable/reducer'
import compare from 'utils/compare'

function sortBySortOrderAndColumn<
  VALUE extends {
    [key in keyof VALUE]: string | number | boolean | null
  },
>(sortColumn: keyof VALUE, sortOrder: SortOrder, defaultColumn: keyof VALUE) {
  return function sortInternal(a: VALUE, b: VALUE) {
    const aValue = a[sortColumn]
    const bValue = b[sortColumn]

    return sortColumn && sortOrder !== SortOrder.NONE
      ? sortOrder === SortOrder.DESC
        ? compare(aValue, bValue)
        : compare(bValue, aValue)
      : compare(a[defaultColumn], b[defaultColumn])
  }
}

export default sortBySortOrderAndColumn
